<template>
  <div class="app-container personality">
    <div class="title">
      <h4>中学生人格测评</h4>
    </div>
    <div class="main">
      <div class="brief-introduce">
        <p>
          　　人格（Personality）起源于古希腊语persona。最初是指古希腊戏剧演员在舞台演出时所戴的面具。在进行生涯决策时，如果能够充分了解自己的人格特质，透过“面具”看清自己，做出最符合人格特质的决策，就可以扔掉“面具”，做真实的自己。
        </p>
        <p>　　九型人格在英文中称为Enneagram。它将人格分为9种类型，在个人成长、婚恋情感、亲子教育、人际沟通、管理与职场、领导力提升等很多领域都有广泛应用。
        </p>
      </div>
      <div class="content">
        <div class="content-title">
          九型人格分类及特点
        </div>
        <ul class="content-box">
          <li class="content-item" v-for="(item, index) in mainData" :key="index">
            <span>{{ item.type }}</span>
            <span>{{ item.name }}</span>
            <span>{{ item.EnglishCall }}</span>
            <span>{{ item.introduction }}</span>
          </li>
        </ul>
      </div>
    </div>

    <div class="start">
      <el-button class="start-btn" type="primary" @click="startTest">开始测评</el-button>
    </div>
  </div>
</template>

<script>
import { getStore } from '@/utils/utils'
export default {
  name: "personality",
  data() {
    return {
      mainData: [
        {
          type: '第一型',
          name: '完美型',
          EnglishCall: 'Perfectionist',
          introduction: '重原则，不易妥协，黑白分明，对自己和别人均要求高，追求完美。',
        },
        {
          type: '第二型',
          name: '助人型',
          EnglishCall: 'Helper/Giver',
          introduction: '渴望与别人建立良好关系，以人为本，乐于迁就他人。',
        },
        {
          type: '第三型',
          name: '成就型',
          EnglishCall: 'Achiever/Motivator',
          introduction: '好胜心强，以成就去衡量自己价值的高低，是一名工作狂。',
        },
        {
          type: '第四型',
          name: '感觉型',
          EnglishCall: 'Artist/Individualist',
          introduction: '情绪化，惧怕被人拒绝，觉得别人不明白自己，我行我素。',
        },
        {
          type: '第五型',
          name: '思想型',
          EnglishCall: 'Thinker/Observer',
          introduction: '喜欢思考分析，求知欲强，但缺乏行动，对物质生活要求不高。',
        },
        {
          type: '第六型',
          name: '忠诚型',
          EnglishCall: 'TeamPlayer/Loyalist',
          introduction: '做事小心谨慎，不易相信别人，多疑虑，喜欢群体生活，尽心尽力工作。',
        },
        {
          type: '第七型',
          name: '活跃型',
          EnglishCall: 'Enthusiast',
          introduction: '乐观，喜新鲜感，爱赶潮流，不喜承受压力。',
        },
        {
          type: '第八型',
          name: '领袖型',
          EnglishCall: 'Leader',
          introduction: '追求权力，讲求实力，不靠他人，有正义感。',
        },
        {
          type: '第九型',
          name: '和平型',
          EnglishCall: 'Peace-maker',
          introduction: '须花长时间做决策，怕纷争，难于拒绝他人，祈求和谐相处',
        },
      ]
    }
  },
  mounted() {

  },
  methods: {
    startTest() {
      // let temp = getStore('LoginData')
      // if(temp){
      //   this.$router.push('/test-content?testType=3')
      // }else{
      //   this.$message({
      //     type: 'error',
      //     message: '请先登录！',
      //     showClose: true,
      //     center: true
      //   })
      //   this.$router.push('/login')
      // }

      this.$store.dispatch('user/judgeLoginStatus').then(flag => {
        if (flag) {
          this.$router.push('/test-content?testType=3')
        } else {
          this.$message({
            type: 'error',
            message: '请先登录！',
            showClose: true,
            center: true
          })
          this.$router.push('/login')
        }
      })
    }
  }
}
</script>

<style scoped lang="less">
.personality {
  padding-top: 2rem;

  .title {
    h4 {
      font-size: 30px;
      color: rgba(var(--themecolor),0.75);
      text-align: center;
    }
  }

  .main {
    .brief-introduce {
      padding: 2rem 0;

      p {
        font-size: 20px;
        color: #999999;
      }
    }

    .content {

      .content-title {
        padding: 1rem;
        margin-bottom: 1rem;
        background-color: rgba(188, 234, 255, 0.71);
        font-size: 24px;
        text-align: center;
        color: #3E4145;
        font-weight: 900;
      }

      .content-box {
        margin-bottom: 3rem;

        .content-item {
          padding: .8rem .2rem;
          border-top: 1px solid #e0e0e0;

          span {
            display: inline-block;
            min-width: 10rem;

            &:last-child {
              margin-left: 4rem;
            }
          }

        }
      }
    }
  }

  .start {
    margin-bottom: 4rem;
    text-align: center;

    .start-btn {
      width: 8rem;
    }
  }

}</style>
<style lang="less">
.start .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.start .el-button--primary,.start .el-button--primary:focus{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
</style>